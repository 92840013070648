.react-select {
  .react-select__control {
    border-radius: $input-border-radius;
  }

  &.firm-name-select {
    width: 300px;
  }

  .react-select__control--is-focused {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
    outline: 0;

    &:hover {
      border-color: $input-focus-border-color;
    }
  }

  .react-select__menu {
    z-index: $zindex-dropdown;
  }
}

.is-invalid {
  .react-select__control {
    border-color: $form-feedback-invalid-color;
    color: $form-feedback-invalid-color;

    &.react-select__control--is-focused {
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
      outline: 0;

      &:hover {
        border-color: $form-feedback-invalid-color;
      }
    }

    .react-select__single-value {
      color: $form-feedback-invalid-color;
    }
  }

  .react-select__menu {
    z-index: $zindex-dropdown;
  }
}
