.faq-container {
  position: relative;
  box-sizing: content-box;
  max-height: 50vh;
  width: 100%;
  aspect-ratio: 1.5;
  padding: 0px;
}

.faq-frame {
  width: 100%;
  height: 100%;
  border: none !important;
}

.accordion-card-header {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.nonactive-card-header {
  border-radius: calc(1.5rem - 1px) !important;
}

.preview-column {
  width: 200px;
}
