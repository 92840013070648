.range {
    display: flex;
    height: 36px;
    width: 100%;

    .track {
        height: 2px;
        width: 100%;
        border-radius: 2px;
        align-self: center;
    }

    .thumb {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 2px $secondary;

        .value {
            position: absolute;
            bottom: -22px;
            color: $body-color;
            padding: 4px;
            border-radius: 4px;
            font-size: $small-font-size;
        }

        .dot {
            height: 7px;
            width: 7px;
            border-radius: 5px;
            background-color: $secondary;

            &.dragged {
                background-color: $primary;
            }
        }
    }
}
