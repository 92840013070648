#content, .sidebar-layout {
  position: relative;
  width: calc(100% - 250px);
  min-height: 100vh;
  transition: width 0.5s;

  &.without-sidebar {
    width: calc(100% - 20px);

    #sidebar-collapse {
      transform: rotate(180deg);
    }
  }

  &.hide-sidebar {
    width: 100%;
  }

  .to-the-front {
    z-index: $zindex-fixed;
  }

  #sidebar-collapse {
    position: absolute;
    left: -12px;
    top: 74px;
    height: 24px;
    width: 24px;
    padding: 0;
    border-radius: 50%;
    z-index: $zindex-fixed;
    transition: 0.5s ease-in-out;
    color: white;

    svg {
      height: 12px;
      width: 12px;
      margin-right: 2px;
    }
  }

  .top-bar {
    background: $body-bg;
    min-height: 73px;

    &.list-header {
      min-height: 34px;
    }

    .search-input {
      .react-select__control {
        min-height: 33px;
      }
    }
  }

  .bottom-navigation {
    background-color: $body-bg;
  }


  .short-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    font-size: larger;
    font-weight: bold;
  }

  .scrollable {
    height: calc(100vh - 73px - 3.75rem);
    overflow: auto;

    &.full-scrollable {
      height: calc(100vh - 3.75rem);
    }

    &.tab-scrollable {
      height: calc(100vh - 280px - 3.75rem);
    }

    table {
      &.sticky {
        thead > tr > th {
          z-index: $zindex-sticky;
        }

        .mw-300-px {
          max-width: 300px;
        }
      }
    }
  }
  
  .deadline-popover {
    z-index: $zindex-popover;
  }

  .with-footer {
    height: calc(100vh - 150px - 3.75rem);
  }

  .content-with-navbar {
    height: calc(100vh - 3.75rem);
  }

  .body-step-with-footer {
    height: calc(100vh - 55px - 3.75rem);
  }

  .nav-tabs .nav-link.active {
    background-color: $white;
    border-bottom-color: $white;
  }

  .widget-body {
    height: 150px;
  }

  .row.horizontal-scrollable {
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;

    .col {
      display: inline-block;
      float: none;
    }
  }

  .certificates-paragraph {
    max-height: 400px;
    overflow-y: auto;
    .certificate-name {
      width: 100%;
      display: inline-block;
    }
  }
}

.mr-1-5 {
  margin-right: 0.4rem !important;
}
