.zindex-dropdown {
    z-index: $zindex-dropdown;
}

.zindex-sticky {
    z-index: $zindex-sticky;
}

.zindex-fixed {
    z-index: $zindex-fixed;
}

.zindex-modal-backdrop {
    z-index: $zindex-modal-backdrop;
}

.zindex-modal {
    z-index: $zindex-modal;
}

.zindex-popover {
    z-index: $zindex-popover;
}

.zindex-tooltip {
    z-index: $zindex-tooltip;
}
