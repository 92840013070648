.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.border {
  border-width: 1px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
}

.rounded-3xl {
  border-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.border-grey-10 {
  --tw-border-opacity: 1;
  border-color: rgb(242 242 242 / var(--tw-border-opacity)) !important;
}

.p-8 {
  padding: 2rem !important;
}
