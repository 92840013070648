.message {
  &.private {
    border-left: 4px solid $purple;
  }
  &.public {
    border-left: 4px solid $yellow;
  }
  &.internal {
    border-left: 4px solid $primary;
  }
}

.dot {
  display: inline-block;
  border-radius: 50%;
  width: 50%;
  background-color: $secondary;
  height: 15px;
  width: 15px;
  margin: 2px;

  &.private {
    background-color: $purple;
  }

  &.public {
    background-color: $yellow;
  }

  &.internal {
    background-color: $primary;
  }

  &.dot-sm {
    height: 5px;
    width: 5px;
  }

  &.dot-lg {
    margin: 0 4px;
    height: 20px;
    width: 20px;
  }
}
