.avatar {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: black;
    color: white;
    height: 64px;
    width: 64px;

    &.avatar-round {
        border-radius: 50%;
    }

    .avatar-image {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;

        &.avatar-image-round {
            border-radius: 50%;
        }
    }

    .avatar-letters {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
    }

    &.sm {
        width: 30px;
        height: 30px;
    }

    &.md {
      width: 50px;
      height: 50px;
    }

    &.lg {
        width: 100px;
        height: 100px;
    }
}

.slide-down-container {
  position: relative;
  min-width: 300px;
  .avatar {
    background-color: white;
  }

  img {
    &.avatar-image-rectangle {
      padding: 8px 0;
      height: 150px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }
}

.avatar-filename {
  padding: 10px;
  font-size: larger;
  text-align: center;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-top: 1px solid lightgrey;
}

.slide-down-container:hover .button-wrapper {
  bottom: 0;
  height: 100%;
}

.avatars {
    display: flex;

    .avatars-item {
        margin-left: -4px;

        .avatars-image {
            box-shadow: 0 0 0 4px $white;
            align-items: center;
            display: flex;
            justify-content: center;
            border-radius: 9999px;
        }
    }
}

.drop-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: inherit;
    border: $input-border-width dashed $input-border-color;
    border-radius: $border-radius;
    cursor: pointer;

    &.avatar-placeholder {
        height: 150px;

        &.avatar-placeholder-round {
            width: 150px;
            border-radius: 50% !important;
        }

        &.avatar-placeholder-rect {
            width: 100%;
        }

        .card-text {
            text-align: center;
        }
    }
}

.button-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  display: flex;
  justify-content: right;
  bottom: 100%;
  width: 100%;
  background-color: rgba(100, 100, 100, 0.3);
  overflow: hidden;
transition: .3s ease;

  .manage-link:hover {
    background-color: lightgrey;
  }

  .manage-link {
    position: relative;
    height: 30px;
    width: 30px;
    color: dimgrey;
    background-color: white;
    border-style: solid;
    border-color: white;
    border-radius: 5px;
    margin-top: 8px;

    svg {
      padding: 5px;
      height: 17px;
      width: 17px;
      cursor: pointer;

            path {
                cursor: pointer;
            }
        }

  }

}

.is-invalid {
    .drop-area {
        border-color: $form-feedback-invalid-color;

        .card-text {
            color: $form-feedback-invalid-color;
        }
    }
}
