.rotate-arrow {
  .arrow {
    transition: 0.3s ease-in-out;
  }

  &.collapsed {
    .arrow {
      transform: rotate(-90deg);
    }
  }

  &.opposite-collapsed {
    .arrow {
      transform: rotate(-180deg);
    }
  }
}

.info-icon {
  color: $primary;
}

.file-upload {
  cursor: pointer;
  color: $primary;
}
