.logo-avatar {
  width: 150px;

  img,.without-avatar {
    position: absolute;
    bottom: 0;
    border: 4px solid white;
    height: 150px;
  }

  .without-avatar {
    background-color: $secondary;
    color: $white;

    .letters {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5em;
    }
  }
}

.attorney-avatar {
  width: 50px;

  img,.without-avatar {
    height: 50px;
  }

  .without-avatar {
    background-color: $secondary;
    color: $white;

    .letters {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5em;
    }
  }
}

.cover {
  background-color: $secondary;
  height: 200px;
  background-position: center;
}

.short-description {
  max-height: 2.75em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-header {
  &.no-attorneys {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: calc(1.5rem - 1px) calc(1.5rem - 1px);
  }
}

.short {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.industry-name {
    max-height: 2.9em;
  }
}
