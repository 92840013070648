@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital@0;1&display=swap');

* {
  @apply font-inter;
}

.font-semibold {
  font-weight: 600 !important;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-normal {
  font-weight: 400 !important;
}

.text-grey-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(128 128 128 / var(--tw-text-opacity)) !important;
}
