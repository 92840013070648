table {
  span.expand {
    width: 10px;

    svg {
      transition: transform 0.3s ease-in-out;
      cursor: pointer;
    }

    &.down svg {
      transform: rotate(90deg);
    }
  }
}

.pagination {
  margin-bottom: 4%;
}
