@import "variables";
@import "@justicebid/jb-ui-theme";
@import "avatar";
@import "content";
@import "tables";
@import "react-select";
@import "dropdowns";
@import "icons";
@import "zindexes";
@import "widgets";
@import "stepper";
@import "bootstrap-slider/dist/css/bootstrap-slider.css";
@import "rc-steps/assets";
@import "profile";
@import "ranges";
@import "cards";
@import "faq";

$btn-action-color: #2563EB;
$btn-action-border-color: #93C5FD;
$btn-action-bg-color: #DBEAFE;

.btn-outline-action {
    color: $btn-action-color;
    border-color: $btn-action-border-color;
    background-color: $btn-action-bg-color;
    font-weight: $font-weight-normal;

    @include hover() {
        color: $btn-action-color;
        background-color: $table-hover-bg;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($btn-action-color, .5);
    }

    &.disabled,
    &:disabled {
        color: $btn-action-color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        &:focus {
            @if $enable-shadows and $btn-active-box-shadow != none {
                @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($btn-action-color, .5));
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: 0 0 0 $btn-focus-width rgba($btn-action-color, .5);
            }
        }
    }
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

body {
  overflow: hidden;
}

.overflow-visible {
    overflow: visible !important;
}

.form-group {
    .invalid-feedback {
        display: block;
    }
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $primary;
}

.page-wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#main-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $zindex-fixed;

    .spinner-border {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}

.alert {
    z-index: $zindex-alert;
}

#collapse-filter {
    z-index: calc($zindex-dropdown - 10);
}

.diversity-table {
    thead th,
    tbody td,
    tbody th {
        text-align: center;
        vertical-align: middle;
    }

    input,
    select {
        min-width: 75px;
    }

    .input-group {
        min-width: 100px;
    }
}

.diversity-scoring-table {
    thead th,
    tbody td {
        text-align: center;
        vertical-align: middle;
    }

    thead th:first-child,
    tbody td:first-child {
        text-align: left;
    }
}

#firmwide-dashboard,
#client-specific-dashboard,
#firmwide-data,
#client-specific-marketing-dashboard,
#tiered-diversity-data {
    canvas {
        max-height: 350px;
    }

    .table-responsive {
        height: 350px;
    }

    .carousel-control-next,
    .carousel-control-prev {
        filter: invert(100%);
    }
}

.embed-oec-faq-pdf,
.embed-oec-privacy-faq-pdf {
    height: calc(100vh - 110.5px - 3.75rem);
}

#add-firm-modal,
.client-specific-upload-form {
    .modal-body {
        .overflow-auto {
            max-height: 200px;
        }
    }
}

.table {
    &.sticky {
        border-collapse: collapse;

        th {
            box-shadow: inset 0 -1px 0 #dee2e6;
            overflow: auto;
            position: sticky;
            top: 0;
            background: white;
        }
    }
}

.popover {
    .popover-body {
        overflow: auto;
        max-height: 300px;
    }
}

.highlight {
    background: $gray-200;
}

.cursor-pointer {
    cursor: pointer;
}

.fill-vertical-height {
    height: 100%;
    display: flex;
    flex-direction: column;

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.max-height-25 {
    max-height: calc(25vh);
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.form-control {
    &.min-height-150 {
        min-height: 150px;
    }
}

.download-progress {
    height: 1px;
}

//copy-paste from bootstrap for validating datetimepickers
.is-invalid {
    .form-control {
        border-color: $form-feedback-invalid-color;

        @if $enable-validation-icons {
            padding-right: $input-height-inner;
            background-image: escape-svg($form-feedback-icon-invalid);
            background-repeat: no-repeat;
            background-position: right $input-height-inner-quarter center;
            background-size: $input-height-inner-half $input-height-inner-half;
        }

        &:focus {
            border-color: $form-feedback-invalid-color;
            box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
        }
    }
}

#company-profiles {
 #filters-section {
     top: 1rem;
     height: calc(100vh - 73px - 2rem - 3.75rem);

     .multi-select-menu, .group-select-menu {
         .checkbox-list {
             overflow-y: auto;
             max-height: 200px;
         }
     }
 }
}

.d-on-hover {
    .hover-hide {
        visibility: hidden;
    }

    &:hover {
        .hover-hide {
            visibility: visible;
        }
    }
}
.align-vertical-center {
    position: absolute;
    top: 40%;
}
.overlay-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $zindex-fixed;
}

.dropdown-toggle-no-arrow::after {
    content: none !important;
}

.per-page-select {
    min-width: 165px;
}

.white-space-pre-line {
    white-space: pre-line !important;
}

.copyright-container {
    img {
        width: 300px;
        height: 76px;
    }
}
